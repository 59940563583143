<template>
  <div>
    <div class="md-layout md-gutter">
      <div class="md-layout-item">
        <form-date
          v-model="filter.date"
          name="date"
          label="Date"
        />
      </div>

      <div class="md-layout-item">
        <ClassroomsSelect
          v-model="filter.classroom_id"
        />
      </div>

      <div class="md-layout-item">
        <PeriodsSelect
          v-model="filter.class_schedule_id"
        />
      </div>

      <div class="md-layout-item md-size-100">
        <md-button
          class="md-success w-100 md-sm"
          @click="$emit('onFilter', filter)"
        >
          Filter
        </md-button>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import { FormDate } from '@/components/Inputs';
import { ClassroomsSelect, PeriodsSelect } from '@/components/Inputs/selects';

export default {
  components: {
    FormDate, ClassroomsSelect, PeriodsSelect,
  },
  props: {
    classes: {
      type: Array,
      default: () => ([]),
    },
  },
  data() {
    return {
      filter: {
        date: moment().format('YYYY-MM-DD'),
        classroom_id: null,
        class_schedule_id: null,
      },
    };
  },
};
</script>

<style scoped lang="scss">
</style>
