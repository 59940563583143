<template>
  <vue-modal
    ref="modal"
    size="sm"
    @close="$emit('close')"
  >
    <template slot="title">
      Upload List
    </template>
    <template slot="body">
      <ul
        class="subtitle"
      >
        <li>
          Files size cannot be greater than 5MB. You can reduce the file size
          <a
            href="https://www.adobe.com/ie/acrobat/online/compress-pdf.html"
            target="_blank"
          >HERE</a>
        </li>
        <li>
          Files extension must be <b>.pdf / .jpg / .jpeg / .png</b>
        </li>
      </ul>

      <hr>

      <form-uploader
        v-model="form.file"
        :max-size="5*1024"
        @error="fireError"
      >
        <b
          v-if="form.file"
          class="text-success"
        >{{ form.file.name }}</b>
        <md-button
          class="md-info md-sm w-100"
        >
          Choose File
        </md-button>
      </form-uploader>
    </template>
    <template slot="footer">
      <md-button
        class="md-primary md-sm"
        @click="uploadForm"
      >
        <md-icon class="material-icons">
          save
        </md-icon>
        Upload
      </md-button>
    </template>
  </vue-modal>
</template>
<script>
import { FormUploader } from '@/components/Inputs';

export default {
  components: {
    FormUploader,
  },
  props: {
    attendanceListId: {
      type: Number,
      default: null,
    },
  },
  data: () => ({
    form: {
      file: null,
    },
  }),
  methods: {
    close() {
      this.$refs.modal.close();
    },
    uploadForm() {
      const formData = new FormData();
      formData.append('file', this.form.file);

      this.guestRequest(`attendance/lists/${this.attendanceListId}/file`, 'post', formData, () => {
        this.fireSuccess('File uploaded successfully!');
        this.$emit('onUploaded');
        this.close();
      });
    },
  },
};
</script>
<style scoped>
.subtitle li {
  margin: 0;
}
.subtitle li a {
  font-weight: bold;
  color: #9c27b0 !important;
}
</style>
