<template>
  <div>
    <md-card class="md-card-global-sales">
      <md-card-header class="md-card-header-icon md-card-header-blue">
        <div class="card-icon">
          <md-icon>assignment</md-icon>
        </div>
      </md-card-header>

      <md-card-content>
        <attendance-filters
          @onFilter="onFilter"
        />

        <div class="md-layout my-3">
          <div class="md-layout-item">
            <md-button
              class="md-warning md-just-icon md-round float-right"
              @click="downloadPdfGlobal(true)"
            >
              <md-icon>print</md-icon>
              <md-tooltip>Print Students without Biometric data</md-tooltip>
            </md-button>

            <md-button
              class="md-info md-just-icon md-round float-right"
              @click="downloadPdfGlobal(false)"
            >
              <md-icon>print</md-icon>
              <md-tooltip>Print all lists</md-tooltip>
            </md-button>

            <md-button
              class="md-primary md-just-icon md-round float-right"
              @click="newAttendanceList"
            >
              <md-icon v-if="showNewDate">
                check
              </md-icon>
              <md-icon v-else>
                add
              </md-icon>
              <md-tooltip>New Attendance</md-tooltip>
            </md-button>

            <md-button
              v-if="showNewDate"
              class="md-primary md-just-icon md-round float-right"
              @click="showNewDate = false"
            >
              <md-icon>
                close
              </md-icon>
              <md-tooltip>Cancel</md-tooltip>
            </md-button>

            <form-date
              v-if="showNewDate"
              v-model="newAttendance.date"
              class="float-right ml-auto mr-3"
              name="date"
              label="Date"
              rules="required"
            />

            <ClassroomsSelect
              v-if="showNewDate"
              v-model="newAttendance.classroom_id"
              class="float-right ml-auto mr-3"
              all-option="All"
            />

            <CoursesSelect
              v-if="showNewDate"
              v-model="newAttendance.course_id"
              class="float-right ml-auto mr-3"
            />
          </div>
        </div>
        <div class="md-layout">
          <div class="md-layout-item md-size-100">
            <vue-table
              v-if="vTable.headers"
              ref="vtable"
              :values="vTable.values"
              :headers="vTable.headers"
              :actions="vTable.actions"
              :options="{
                tableClass:'table'
              }"
              @changed="getAttendances"
              @onShowDetails="onShowDetails"
              @onRemoveList="onRemoveList"
              @onDownloadPdf="onDownloadPdf"
              @onUpload="onUpload"
              @onPreview="onPreview"
            />
          </div>
        </div>
      </md-card-content>
    </md-card>

    <uploadFileModal
      v-if="selItem"
      :attendance-list-id="selItem.attendance_list_id"
      @close="selItem = null"
      @onUploaded="$refs.vtable.init()"
    />
  </div>
</template>

<script>
import VueTable from '@/components/Tables/VueTable.vue';
import moment from 'moment';
import { FormDate } from '@/components/Inputs';
import { CoursesSelect, ClassroomsSelect } from '@/components/Inputs/selects';
import { extend } from 'vee-validate';
import { required } from 'vee-validate/dist/rules';
import downloadFile from '@/utils/downloadFile';
import AttendanceFilters from './AttendanceFilters.vue';
import uploadFileModal from './uploadFileModal.vue';

extend('required', required);

export default {
  components: {
    VueTable,
    AttendanceFilters,
    FormDate,
    CoursesSelect,
    ClassroomsSelect,
    uploadFileModal,
  },
  props: [],
  data() {
    return {
      vTable: {
        headers: [
          {
            title: 'date',
            mask: 'date',
            sortable: true,
            dateFormat: true,
          },
          {
            title: 'classroom.classroom_code',
            mask: 'class',
            sortable: false,
          },
          {
            title: 'classroom.course_level.name',
            mask: 'course',
            sortable: false,
          },
          {
            title: 'classroom.class_schedule.period',
            mask: 'Schedule',
            sortable: false,
          },
          {
            title: 'attendance_enrolments',
            mask: 'enrolments',
            callback: (item) => item.length,
          },
          {
            title: 'empty',
            mask: ' ',
            htmlFormat: true,
            callback: (isEmpty) => {
              if (!isEmpty) return '';

              return '<button style="background-color:#FF5147; border: unset; padding: .5em 1em; color:white; border-radius: 5px;" disabled>Empty</button>';
            },
          },
        ],
        actions: [
          {
            buttonClass: 'md-success',
            tooltip: 'Preview Uploaded List',
            callback: 'onPreview',
            icon: 'visibility',
          },
          {
            buttonClass: 'md-primary',
            tooltip: 'Upload List',
            callback: 'onUpload',
            icon: 'file_upload',
          },
          {
            buttonClass: 'md-info',
            tooltip: 'Download Pdf',
            callback: 'onDownloadPdf',
            icon: 'cloud_download',
          },
          {
            buttonClass: 'md-warning',
            tooltip: 'Show Details',
            callback: 'onShowDetails',
            icon: 'search',
          },
          {
            buttonClass: 'md-danger',
            tooltip: 'Delete List',
            callback: 'onRemoveList',
            icon: 'close',
          },
        ],
        values: {},
      },
      filters: {
        course_id: null,
        date: moment().format('YYYY-MM-DD'),
        classroom_id: null,
        class_schedule_id: null,
      },
      showNewDate: false,
      newAttendance: {
        date: moment().format(this.$store.getters['school_settings/settings'].date_format),
        classroom_id: null,
        course_id: null,
      },
      selItem: null,
    };
  },
  mounted() {
    this.$refs.vtable.init();
  },
  methods: {
    getAttendances(params) {
      const parameters = params;

      this.request(this.$API.ATTENDANCE_LIST.paginableUrl(parameters), 'get', { ...this.filters }, ({ data }) => {
        this.vTable.values = data;
        this.vTable.values.data = this.vTable.values.data.map((x) => {
          x.onPreview = !!x.file;

          return x;
        });
      });
    },
    downloadPdfGlobal(noBiometric = false) {
      if (this.filters.date) {
        if (!moment(this.filters.date, 'DD-MM-YYYY', true).isValid()) {
          this.filters.date = moment(this.filters.date).format('DD-MM-YYYY');
        }
      } else {
        this.fireError('Please use the Filter by Date');
        return;
      }
      const parameters = {
        ...this.filters,
        download: true,
        noBiometric,
      };

      this.request(this.$API.ATTENDANCE_LIST, 'download', parameters, ({ data }) => {
        const filename = `Attendance_lists${noBiometric ? '_no_biometric' : ''}_${this.dateFormat(this.filters.date)}.pdf`;
        downloadFile(data, filename);
      });
    },
    newAttendanceList() {
      if (!this.showNewDate) {
        this.showNewDate = true;
      } else {
        if (!this.newAttendance.date) {
          this.fireError('Select a Date');
          return;
        }
        if (!this.newAttendance.course_id) {
          this.fireError('Select a Course');
          return;
        }

        this.request(this.$API.ATTENDANCE_LIST, 'post', this.newAttendance, (data) => {
          this.fireSuccess(data);
          this.showNewDate = false;
          this.filters.date = this.newAttendance.date;
          this.newDate = moment().format(this.$store.getters['school_settings/settings'].date_format);
          this.$refs.vtable.init();
        });
      }
    },
    onShowDetails(item, isMiddleClick) {
      this.openLink({ name: 'AttendanceDetails', params: { id: item.attendance_list_id } }, isMiddleClick);
    },
    onRemoveList(item) {
      this.fireConfirm(
        'Deleting Attendance List',
        'Are you sure you want to Delete this List?',
      ).then(() => {
        this.request(this.$API.ATTENDANCE_LIST + item.attendance_list_id, 'delete', null, () => {
          this.fireSuccess('List deleted succesfully!');
          this.$refs.vtable.init();
        });
      });
    },
    onDownloadPdf(item) {
      this.request(`${this.$API.ATTENDANCE_LIST + item.attendance_list_id}/pdf`, 'download', null, ({ data }) => {
        const filename = `Attendance_list_${item.classroom.classroom_code}_${moment(item.date).format('DD-MM-YYYY')}.pdf`;
        downloadFile(data, filename);
      });
    },
    onFilter(filters) {
      this.filters = filters;
      this.$refs.vtable.init();
    },
    onUpload(item) {
      this.selItem = item;
    },
    onPreview(item) {
      if (item.file_id) {
        this.openFileUrl(item.file_id);
      }
    },
  },
};
</script>

<style scoped>
</style>
