import { render, staticRenderFns } from "./AttendanceFilters.vue?vue&type=template&id=8480eb2e&scoped=true&"
import script from "./AttendanceFilters.vue?vue&type=script&lang=js&"
export * from "./AttendanceFilters.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8480eb2e",
  null
  
)

export default component.exports